import { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { ZoomMtg } from "@zoomus/websdk";
import {
  isEmpty,
  sliceString,
  splitString,
} from "../../../custom_utilities/custom_useful_functions";
// import { url_55 } from "../../../custom_utilities/api_services";
import "./zoom_component.scss";

const Zoom = (props) => {
  let { history, userData, liveStreamData } = props;

  // const [participantDurations, setParticipantDurations] = useState({});
  // console.log("parcicipantsDurations", participantDurations);

  useEffect(() => {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    joinMeeting(liveStreamData);

    // Set up participant event listeners
    // ZoomMtg.inMeetingServiceListener("onUserJoin", handleUserJoin);
    // ZoomMtg.inMeetingServiceListener("onUserLeave", handleUserLeave);

    // Start periodic API calls
    // const intervalId = setInterval(() => {
    //   sendDurationsToAPI();
    // }, 5000);

    // Cleanup listeners and interval on component unmount
    // return () => {
    //   ZoomMtg.inMeetingServiceListener("onUserJoin", null);
    //   ZoomMtg.inMeetingServiceListener("onUserLeave", null);
    //   clearInterval(intervalId);
    // };
  }, []);

  // const handleUserJoin = (data) => {
  //   const userId = data.userId;
  //   setParticipantDurations((prev) => ({
  //     ...prev,
  //     [userId]: { joinTime: new Date() },
  //   }));
  //   console.log(`User joined--------------------->: ${userId}`);
  // };

  // const handleUserLeave = (data) => {
  //   const userId = data.userId;
  //   setParticipantDurations((prev) => {
  //     const joinTime = prev[userId]?.joinTime || new Date();
  //     const leaveTime = new Date();
  //     const duration = (leaveTime - joinTime) / 1000; // Duration in seconds
  //     console.log(
  //       `User left------------------------>: ${userId}, Duration: ${duration} seconds`
  //     );
  //     return {
  //       ...prev,
  //       [userId]: { ...prev[userId], leaveTime, duration },
  //     };
  //   });
  // };

  // const sendDurationsToAPI = async () => {
  //   // Iterate through participants and calculate durations
  //   const payload = Object.entries(participantDurations).map(
  //     ([userId, data]) => {
  //       const joinTime = data.joinTime;
  //       const currentTime = new Date();
  //       const duration = data.duration || (currentTime - joinTime) / 1000; // Update duration if user hasn't left
  //       return { userId, duration };
  //     }
  //   );

  //   try {
  //     // Replace with your API endpoint
  //     const response = await axios.post(url_55, {
  //       participants: payload,
  //     });
  //     console.log("API response:", response.data);
  //   } catch (error) {
  //     console.error("Error sending participant durations to API:", error);
  //   }
  // };

  // const showZoomDiv = () => {
  //   document.getElementById("zmmtg-root").style.display = "block";
  // };

  const decrypt_functionality = (val) => {
    const CryptoJS = require("crypto-js");
    let encrypted = val;
    let key = "0CiuKpLCZbX7gBCyYTka4USxTnh7ssXKk8bJ6Y3TXPc=";

    encrypted = atob(encrypted);
    encrypted = JSON.parse(encrypted);

    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    const value = encrypted.value;
    key = CryptoJS.enc.Base64.parse(key);

    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
    });

    decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    return decrypted.split("*")[1];
  };

  const joinMeeting = (element) => {
    const meetConfig = {
      meetingNumber: decrypt_functionality(element.zoom_id),
      leaveUrl: "https://learning.motion.ac.in/user_dashboard",
      userEmail: decrypt_functionality(element.zoom_email),
      passWord: decrypt_functionality(element.password),
      apiKey: decrypt_functionality(element.api_key),
      apiSecretKey: decrypt_functionality(element.api_secret_key),
      sdkKey: decrypt_functionality(element.sdk_key),
      sdkSecretKey: decrypt_functionality(element.sdk_secret_key),
    };

    ZoomMtg.generateSDKSignature({
      meetingNumber: meetConfig.meetingNumber,
      sdkKey: meetConfig.sdkKey,
      sdkSecret: meetConfig.sdkSecretKey,
      role: 0,
      success: function (res) {
        // showZoomDiv();
        ZoomMtg.init({
          leaveUrl: meetConfig.leaveUrl,
          isSupportAV: true,
          // meetingInfo: ["topic", "host", "participants"],

          success: () => {
            ZoomMtg.join({
              signature: res.result,
              meetingNumber: meetConfig.meetingNumber,
              userName:
                (!isEmpty(userData.roll_no) ? userData.roll_no : "Not Roll") +
                "-" +
                (!isEmpty(userData.name) ? userData.name : "Not Registered"),
              sdkKey: meetConfig.sdkKey,
              userEmail: meetConfig.userEmail,
              passWord: meetConfig.passWord,
              success: () => {
                console.log("Join Meeting Success");
              },
              error: (error) => {
                console.error("Error Joining Meeting: ", error);
              },
            });
          },

          error: (error) => {
            console.error("Error Initializing Meeting: ", error);
          },
        });
      },
    });
  };

  // return (
  //   <div className="App">
  //     Loading Class
  //     <div>
  //       <h3>Participant Durations</h3>
  //       <ul>
  //         {Object.entries(participantDurations).map(([userId, data]) => (
  //           <li key={userId}>
  //             User {userId}:{" "}
  //             {data.duration
  //               ? `${data.duration} seconds`
  //               : "Currently in meeting"}
  //           </li>
  //         ))}
  //       </ul>
  //     </div>
  //   </div>
  // );

  return <div className="App">Loading Class</div>;
};

const mapStateToProps = (state) => {
  return {
    liveStreamData: state.userActivity.liveStreamData,
    loader: state.ui.loader,
    userData: state.auth.user,
    userPreference: state.userPreference,
  };
};

export default connect(mapStateToProps)(Zoom);
